import {LitElement, html} from 'lit';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';
import {routingMixin} from '@jack-henry/web-component-router';
import {retrieveDeviceId} from '../../js/device-fingerprint.js';

/** @typedef {import('@jack-henry/web-component-router').BasicRoutingInterface} BasicRoutingInterface */
/** @typedef {import('@jack-henry/web-component-router').Context} Context */
/** @typedef {import('@jack-henry/web-component-router').RouteTreeNode} RouteTreeNode */

/**
 * @constructor
 * @extends {LitElement}
 * @implements {BasicRoutingInterface}
 */
const LoginCompleteContainerBase = routingMixin(LitElement);

/** @customElement */
class LoginCompleteElement extends LoginCompleteContainerBase {
  static get is() {
    return 'login-complete';
  }

  /**
   * @param {!RouteTreeNode} currentNode
   * @param {!RouteTreeNode|undefined} nextNodeIfExists
   * @param {string} routeId
   * @param {!Context} context
   */
  async routeEnter(currentNode, nextNodeIfExists, routeId, context) {
    super.routeEnter(currentNode, nextNodeIfExists, routeId, context);

    try {
      const deviceId = retrieveDeviceId();
      await AuthController.exchangeJhidToken({deviceId});

      const redirectUri = context.query.get('redirect_to') || '/a/';
      window.location.href = redirectUri;
    } catch (e) {
      console.error(e);
      if (e.status === 401) {
        window.location.href = '/a/login/logout';
      }
    }
  }

  render() {
    return html`
    `;
  }
}
customElements.define(LoginCompleteElement.is, LoginCompleteElement);
export default LoginCompleteElement;
